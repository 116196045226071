import React from "react";
import Hero from "../../sections/templates/landing5/Hero";
import Testimonial from "../../sections/templates/landing5/Testimonial";
import Feature from "../../sections/templates/landing5/Feature";
import Content from "../../sections/templates/landing5/Content";
import Fact from "../../sections/templates/landing5/Fact";
import Reviews from "../../sections/templates/landing5/Reviews";
import Author from "../../sections/templates/landing5/Author";
import Pricing from "../../sections/templates/landing5/Pricing/Pricing";
import Offer from "../../sections/templates/landing5/Offer";
import Faq from "../../sections/templates/landing5/Faq";
import CTA from "../../sections/templates/landing5/CTA";
import PageWrapper from "../../components/PageWrapper";

const LandingPage5 = () => {
  return (
    <>
      <PageWrapper>
        <Hero />
        <Testimonial />
        <Feature />
        <Content />
        <Offer />
        <Fact />
        <Reviews />
        <Author />
        <Pricing />
        <Faq />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default LandingPage5;
